import React from "react";
import Hero from "../../components/Hero/Hero.jsx";
import About from "../../components/About/About.jsx";
import Project from "../../components/Project/Project.jsx";
import Contact from "../../components/Contact/Contact.jsx";

import "./MainPage.css";

function MainPage() {
  // const { activeComponent } = useActiveComponent();

  // const renderComponent = () => {
  //   switch (activeComponent) {
  //     case "Hero":
  //       return <Hero />;
  //     case "About":
  //       return <About />;
  //     case "Project":
  //       return <Project />;
  //     case "Contact":
  //       return <Contact />;
  //     default:
  //       return <Hero />;
  //   }
  // };

  return <>
  {/* {renderComponent()} */}
  <Hero />
  <About />
  <Project />
  <Contact />
  </>;
}

export default MainPage;
