import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";


export const professionalData = [
    {
        date: 'Jan 2023 - Jul 2023',
        title: 'Full-Stack Coding Bootcamp',
        location: 'University of North Carolina at Chapel Hill',
        text: 'Practiced Agile methodology while developing several full-stack applications using JavaScript, React, Node.js, Express.js, SQL, MySQL and MongoDB.',
        icon: { faGraduationCap },
        category: { tag: 'Education' },
        link: { url: 'https://github.com/pexApred', text: 'See Projects' },
    },
    {
        date: 'Nov 2021 - Jul 2023',
        title: 'Business Owner/Operator',
        location: 'Pizza Time Inc',
        text: 'By integrating software development into restaurant operations, I significantly increased revenue by 50% and enhanced customer satisfaction and sales through a streamlined operational approach.',
        icon: 'fas fa-briefcase',
        category: { tag: 'Professional Experience' },
        link: { url: 'https://pizzatimesc.com', text: 'Visit Website' },
    },
    {
        date: 'Jul 2015 - Jan 2021',
        title: 'Co-Owner/Operator',
        location: 'Pizza Time Inc',
        text: 'I effectively managed the Toast, Inc POS system to enhance efficiency and customer engagement, increasing sales by 25% through strategic initiatives and steering the business through the COVID-19 pandemic by transitioning to a takeout-only model that prioritized staff safety and ensured continuous operations.',
        icon: 'fas fa-briefcase',
        category: { tag: 'Professional Experience' },
        link: { url: 'https://pizzatimesc.com', text: 'Visit Website' },
    },
    {
        date: 'Jan 2017 - Jul 2019',
        title: "Associate in Applied Science",
        location: 'Johnson & Wales University',
        text: 'Achieved excellence in culinary arts and gained practical experience to enhance procedures and operations of Pizza Time, Inc.',
        icon: 'fas fa-graduation-cap',
        category: { tag: 'Education' },
        link: { url: 'https://github.com/pexApred', text: 'See Projects' },
    },
    {
        date: 'Jul 2013 - Jan 2015',
        title: 'General Manager & Chef',
        location: 'Pizza Time Inc',
        text: 'I excelled in leading and training teams in a high-pressure kitchen environment, demonstrating robust management and problem-solving skills to meet tight deadlines and maintain customer satisfaction.',
        icon: 'fas fa-briefcase',
        category: { tag: 'Professional Experience' },
        link: { url: 'https://pizzatimesc.com', text: 'Visit Website' },
    },
    {
        date: 'Aug 2009 - Jun 2013',
        title: "Bachelor of Science",
        location: 'Wofford College',
        text: 'Acquired a solid foundation in Chemistry and related disciplines. Acquired and built independent learning skills and practices that have propelled my continuous learning journey.',
        icon: 'fas fa-graduation-cap',
        category: { tag: 'Education' },
        link: { url: 'https://github.com/pexApred', text: 'See Projects' },
    }
];

export const projectData = [
    {
        id: 1,
        title: "The Wonderful World of dR slide",
        description: `The Wonderful World of dR Slide" is an innovative web application designed to complement the "Book of Riddles." It serves as an interactive platform where book purchasers can log in, solve riddles, and engage with a community of fellow riddle enthusiasts. The website not only acts as a 'check-answer' system but also offers a unique, gamified experience by featuring user profiles, personal riddle stats, hints, solutions, and a competitive leaderboard.`,
        image: "../wwofdrslideimg.png",
        link: "https://www.thewonderfulworldofdrslide.com/",
    },
    {
        id: 2,
        title: "JourneyVerse",
        description: `This project demonstrates my ability to design and implement a scalable,
              user-focused MERN stack single-page application. It features a MongoDB
              database with a GraphQL API and a React front-end. The application is
              deployed on Heroku, includes user authentication using JWT, and protects
              sensitive information on the server. The project emphasizes collaborative
              skills, problem-solving, and the ability to work with real-world data to
              solve user-focused problems.`,
        image: "../JourneyVerse-screenshot.png",
        link: "https://journeyverse-2c53482d7e17.herokuapp.com/",
    },
    {
        id: 3,
        title: "codeThread",
        description: `This platform allows users to purchase bespoke cohort t-shirts, 
          offering customization options like cohort selection, shirt size, and color, 
          thereby personalizing each garment. Key features of this project include a RESTful 
          API built with Node.js and Express.js, a user-friendly interface rendered via 
          Handlebars.js, and a robust MySQL database managed through Sequelize ORM. We 
          ensured responsive design for optimal user experience across devices, implemented 
          authentication for security, and utilized environment variables to protect 
          sensitive data. This project not only showcases technical prowess in creating 
          a full-stack application but also embodies our dedication to creating a product 
          that celebrates academic achievements and cohort solidarity.`,
        image: "../codeThread.png",
        link: "https://pacific-inlet-31648.herokuapp.com/",
    },
    {
        id: 4,
        title: "Literacy Lane",
        description: `This project underscores my proficiency in using CSS frameworks,
              integrating multiple server-side APIs, implementing client-side storage,
              and delivering an interactive and responsive UI. It exhibits my commitment
              to clean coding practices, clear documentation, and effective team
              collaboration, culminating in a successful deployment on GitHub Pages.`,
        image: "../Literacy-Lane-Update.png",
        link: "https://pexapred.github.io/P1-G7-Literacy-Lane/index.html",
    },
    {
        id: 5,
        title: "Pizza Time Inc",
        description: "",
        image: "../pizzaTime.png",
        link: "https://www.pizzatimesc.com/",
    },
];

export const contactLinks = [
    {
        title: "GitHub",
        link: "https://github.com/pexApred",
        icon: faGithub,
    },
    {
        title: "LinkedIn",
        link: "https://linkedin.com/in/emmanuellakis",
        icon: faLinkedin,
    },
    // {
    //     title: "919.714.9693",
    //     link: "tel:919.714.9693",
    //     icon: faPhone,
    // },
    // {
    //     title: "manolilakis@gmail.com",
    //     link: "mailto:manolilakis@gmail.com",
    //     icon: faPaperPlane,
    // },
];