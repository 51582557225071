import React from "react";
import { ScrollProvider } from "./utils/Context.jsx";
import NavBar from "./components/NavBar/NavBar.jsx";
import MainPage from "./pages/MainPage/MainPage.jsx";
import About from "./components/About/About.jsx";
import Project from "./components/Project/Project.jsx";
import Contact from "./components/Contact/Contact.jsx";

import "./App.css";


function App() {

  return (
    <ScrollProvider>
      <div className="App">
      <div className="desktop-note">
      Thank you for visiting! <br/>My website is currently being optimized for desktop viewing. <br/>For the best experience, please visit on a mobile device.
      </div>

        <NavBar />
        <MainPage />
        {/* <About/>
        <Project/>
        <Contact/> */}
      </div>
    </ScrollProvider>
  );
}

export default App;