import React, { useState } from "react";

import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    websiteURL: "",
    budget: "",
    source: "",
    details: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    const requiredFields = [
      "fullName",
      "email",
      "companyName",
      "websiteURL",
      "budget",
      "source",
      "details",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    const emailLink = `mailto:elakis.dev@gmail.com?subject=New Contact Form Submission&body=Full Name: ${formData.fullName}%0AEmail: ${formData.email}%0A%0APhone Number: ${formData.phoneNumber}%0ACompany Name: ${formData.companyName}%0ACurrent Website URL: ${formData.websiteURL}%0ABudget: ${formData.budget}%0ASource: ${formData.source}%0A%0AMessage: ${formData.details}`;

    window.location.href = emailLink;
  };
  return (
    <div id="contact">
      <div className="contact-container">
        <div className="contact-info">
          <div className="contact-header">
            <h1 className="contact-tagline">Share Your Vision, </h1>
            <h2 className="contact-subTagline">Turn CONCEPT to CODE</h2>
          </div>
          <h2 className="contact-title">
            Let's Craft Your Digital Future. Ready to Start?
          </h2>
          <h2 className="contact-subtitle">Connect Below.</h2>
          <form className="contact-form">
            <div className="fn-container">
              <input
                type="text"
                id="fullName"
                className={`contact-input ${errors.fullName ? "error" : ""}`}
                name="fullName"
                placeholder="Full Name"
                required
                value={formData.fullName}
                onChange={handleChange}
              />
              <label className="labels" htmlFor="fullname">
                Name: <span className="required">*</span>
              </label>
            </div>
            <div className="email-container">
              <input
                type="email"
                id="email"
                className={`contact-input ${errors.email ? "error" : ""}`}
                name="email"
                placeholder="Email"
                required
                value={formData.email}
                onChange={handleChange}
              />
              <label className="labels" htmlFor="email">
                Email: <span className="required">*</span>
              </label>
            </div>
            <div className="phone-container">
              <input
                type="tel"
                id="phoneNumber"
                className="contact-input"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <label className="labels" htmlFor="phoneNumber">
                Phone Number:
              </label>
            </div>
            <div className="company-container">
              <input
                type="text"
                id="companyName"
                className={`contact-input ${errors.companyName ? "error" : ""}`}
                name="companyName"
                required
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleChange}
              />
              <label className="labels" htmlFor="companyName">
                Company: <span className="required">*</span>
              </label>
            </div>
            <div className="source-container">
              <select
                id="source"
                className={`contact-input ${errors.source ? "error" : ""}`}
                name="source"
                value={formData.source}
                onChange={handleChange}
              >
                <option value="">Select an option...</option>
                <option value="friend">Friend</option>
                <option value="website">Website</option>
                <option value="search">Google/Bing</option>
                <option value="social">Social Media</option>
                <option value="other">Other</option>
              </select>
              <label className="labels" htmlFor="source">
                How did you hear about me? <span className="required">*</span>
              </label>
            </div>
            <div className="website-container">
              <input
                type="text"
                id="websiteURL"
                className={`contact-input ${errors.websiteURL ? "error" : ""}`}
                name="websiteURL"
                placeholder="Current Website URL"
                required
                value={formData.websiteURL}
                onChange={handleChange}
              />
              <label className="labels" htmlFor="websiteURL">
                Current Website URL: <span className="required">*</span>
              </label>
            </div>
            <div className="budget-container">
              <select
                id="budget"
                className={`contact-input ${errors.budget ? "error" : ""}`}
                name="budget"
                required
                value={formData.budget}
                onChange={handleChange}
              >
                <option value="">Select an option...</option>
                <option value="$0-750(Minimal)">$0-750 (Minimal)</option>
                <option value="$750-2500(Basic)">$750-2500 (Basic)</option>
                <option value="$2500-5000(Intermediate)">
                  $2500-5000 (Intermediate)
                </option>
                <option value="> $5000 (Advanced)">
                  {">"}$5000 (Advanced)
                </option>
              </select>
              <label className="labels" htmlFor="budget">
                Budget: <span className="required">*</span>
              </label>
            </div>

            <div className="details-container">
              <textarea
                id="details"
                className={`contact-input ${errors.details ? "error" : ""}`}
                name="details"
                placeholder="More Details"
                value={formData.details}
                onChange={handleChange}
                required
              ></textarea>
              <label className="labels" htmlFor="details">
                More Details: <span className="required">*</span>
              </label>
            </div>
            <button
              type="submit"
              className="contact-button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
