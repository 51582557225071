import React, { useState } from "react";

import { projectData } from "../../utils/data";
import "./Project.css";

function Project() {
  return (
    <div id="builds" className="build-container">
      <h1 className="build-title">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          strokeWidth="3"
          stroke="#C0AD6D"
          fill="none"
          className="duration-300 transform transition-all"
          style={{ width: "24px", height: "24px" }}
        >
          <rect
            x="8.5"
            y="9.25"
            width="10.68"
            height="45.49"
            rx=".2"
            strokeLinecap="round"
          ></rect>
          <path
            strokeLinecap="square"
            d="M8.5 14.26h3.8M8.5 19.38h3.8M8.5 24.51h3.8M8.5 29.64h3.8M8.5 34.77h3.8M8.5 39.72h3.8M8.5 44.66h3.8M8.5 49.61h3.8"
          ></path>
          <path
            d="M24.16 9.83v44.74a.19.19 0 00.18.18h31a.19.19 0 00.15-.29l-31-44.73a.18.18 0 00-.33.1z"
            strokeLinecap="round"
          ></path>
          <path
            d="M29.38 26.48v22.71a.09.09 0 00.08.08h15.17a.2.2 0 00.16-.31L29.53 26.43a.08.08 0 00-.15.05z"
            strokeLinecap="round"
          ></path>
        </svg> */}
        curated constructs{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          strokeWidth="3"
          stroke="#B8755A"
          fill="none"
          className="duration-300 transform transition-all"
          style={{ width: "24px", height: "24px" }}
        >
          <circle cx="31.99" cy="19.36" r="5.41" strokeLinecap="round"></circle>
          <path
            strokeLinecap="round"
            d="M31.99 24.77L16.98 56.89M31.99 24.77L47 56.73M31.99 7.11v6.84M17.2 40.07h29.82"
          ></path>
        </svg>
      </h1>
      <div className="projects">
        {projectData.map((project) => (
          <div key={project.id} className="project-card">
            <h2 className="project-title">{project.title}</h2>
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              <img
                className="project-image"
                variant="top"
                src={project.image}
                alt={`${project.title} screenshot`}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Project;
