import React from "react";
import "./About.css";

function About() {
  return (
    <div id="about" className="bio-container">
      <div className="bio-container">
        <h1 className="bio-title">Hi <span className="bio-title-greek"> | ΓΕΙΑ ΣΑΣ!</span></h1>
        <h1 className="bio-subtitle">Emmanuel Lakis 
        <span className="bio-subtitle-greek"> | Εμμανουήλ Λάκης</span>,</h1>
        <div className="bio-description-container">
          <p className="bio-item">
            I am driven by a lifelong commitment to <span className="bi-section">refining my skills and
            perpetual learning.</span>
          </p>
          <p className="bio-item">
            In the fast-paced kitchen of my early career, <span className="bi-section">I honed
            an appreciation for operational efficiency, meticulous detailing,
            and prioritizing customer satisfaction.</span>
          </p>
          <p className="bio-item">
            I approach web development methodically – <span className="bi-section">hypothesizing, testing,
            iterating – continuously refining the product until achieving an
            elegant solution to real-world problems.</span>
          </p>
          <p className="bio-item">
            My work is fueled by creativity, scientific <span className="bi-section">discipline, and an
            entrepreneurial spirit dedicated to pushing boundaries. I
            continually seek opportunities to apply my multidisciplinary
            background in crafting compelling digital products.</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
