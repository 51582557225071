import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useScroll } from "../../utils/Context";
import "./NavBar.css";

function NavBar() {
  const [scrollProgress, setScrollProgress] = useState(0);
  const { scrollToSection } = useScroll();

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = window.scrollY;
      const windowHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = (scrollTop / windowHeight) * 100;
      setScrollProgress(scrollPercentage);
    };

    window.addEventListener("scroll", updateScrollProgress);

    return () => window.removeEventListener("scroll", updateScrollProgress);
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault(); 
    window.scrollTo({
      top: 0,
      behavior: "smooth" 
    });
  };

  return (
    <nav className="nav-container">
      <a onClick={scrollToTop} className="logo">
        Emmanuel Lakis 
        {/* <span className="logo-greek">| Εμμανουήλ Λάκης </span> */}
      </a>
      <ul className="section-links">
        <li>
          <button
            className="links"
            onClick={() => scrollToSection("builds")}
          >
            builds
          </button>
        </li>{" "}
        <li>
          <button className="links" onClick={() => scrollToSection("about")}>
            about
          </button>
        </li>
        <li>
          <button
            className="links"
            onClick={() => scrollToSection("contact")}
          >
            contact
          </button>
        </li>
      </ul>
      <div className="scroll-base"></div>
      <div className="scroll-indicator" style={{ width: `${scrollProgress}%` }}>
      </div>
    </nav>
  );
}

export default NavBar;
