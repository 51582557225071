import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { contactLinks } from "../../utils/data";
import { useScroll } from "../../utils/Context";

import "./Hero.css";

function Hero() {
  const { scrollToSection } = useScroll();

  const [targetDate, setTargetDate] = useState({ month: new Date().getMonth(), day: new Date().getDate() });
  const [animating, setAnimating] = useState(false);
  const dateAnimation = useSpring({
    from: { value: 0 },
    to: { value: 100 }, 
    delay: 500,
    onStart: () => setAnimating(true),
    onRest: () => setAnimating(false),
  });

  const props = useSpring({
    to: { transform: "scaleY(3)" },
    from: { transform: "scaleY(1)" },
    // reset: true,
    delay: 500,
  });

  const spanProps = useSpring({
    to: { transform: "scaleY(1)" },
    from: { transform: "scaleY(3)" },
    // reset: true,
    delay: 500,
  });

  const animatedDay = dateAnimation.value.to({
    range: [0, 100],
    output: [1, targetDate.day]
  }).to(Math.round);

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const animatedMonth = dateAnimation.value.to({
    range: [0, 100],
    output: [0, targetDate.month] 
  }).to(val => months[Math.min(Math.floor(val), 11)]);

  return (
    <div className="hero-container">
      <div className="media-container">
        <ul className="media-icons">
          {contactLinks.map((link, index) => (
            <li key={index}>
              <a href={link.link} className="social-links">
                <FontAwesomeIcon icon={link.icon} className="media" />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="date-container">
      <animated.h1 className="month">
          {animatedMonth}
        </animated.h1>
        <animated.h1 className="day">
          {animatedDay}
        </animated.h1>
        <h1 className="schedule">
          <FontAwesomeIcon icon={faCalendarCheck} />
           {' '}available
        </h1>
      </div>
      <div className="hero-section">
        <div className="tag-container">
          <animated.h2 style={spanProps} className="span-tag">
            {" "}
            strategic
          </animated.h2>
          <div className="inline-tag">
            <animated.h1 style={props} className="tag">
              DESIGNER
            </animated.h1>
            <animated.h2 style={spanProps} className="span-tag and-tag">
              &
            </animated.h2>
          </div>
          <animated.h1 style={props} className="tag">
            DEVELOPER
          </animated.h1>
        </div>
        <div className="summary-container">
          <p className="summary1">I am a developer and UI/UX</p>
          <p className="summary2">
            designer continuously honing my craft. I approach projects with a
            unique, solutions-oriented perspective gained from real-world
            experience. At my core, I'm a lifelong learner committed to pushing
            the boundaries of what's possible.
          </p>
        </div>
        <button
          className="contact-btn"
          onClick={() => scrollToSection('contact')}
        >
          Let's Connect <FontAwesomeIcon icon={faArrowRight} />{" "}
        </button>
      </div>
    </div>
  );
}

export default Hero;
